import { FileFormat } from '@api/models'
import { toast } from '@components/common'
import HttpStatusCode from '@components/common/Helper/HttpStatusCode'
import { ERROR_REQUEST } from '@constants'

function downloadFile(data: any, type: string = null, fileName: string = 'report') {
  const blob = type === null ? new Blob([data]) : new Blob([data], { type })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = url
  link.download = `${fileName}`

  // Append to html link element page
  document.body.appendChild(link)

  // Start download
  link.click()

  // Clean up and remove the link
  link.parentNode.removeChild(link)
  URL.revokeObjectURL(url)
}

enum FileFormatEnum {
  PDF = 'pdf',
  CSV = 'csv',
  EXCEL = 'excel',
}

const contentTypes: Record<string, string> = {
  pdf: 'application/pdf',
  csv: 'text/csv;charset=utf-8',
  excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export function downloadFileByType(response: any, fileType: FileFormat) {
  const contentType = contentTypes[fileType]
  const fileName = getFileNameFromHeader(response) || `default.${fileType}`
  downloadFile(response.data, contentType, fileName)
}

function getFileNameFromHeader(response: any) {
  const contentDisposition = response.headers['content-disposition']

  if (!contentDisposition) {
    return 'report'
  }

  const filenameRegex = /filename[^;=\n]*=(['"]?)([^;'\n]*)\1|filename\*=UTF-8''([^;\n]*)/i
  const matches = contentDisposition.match(filenameRegex)

  return matches ? decodeURIComponent(matches[2] || matches[3]) : 'report'
}

export async function reportErrorHandler({ response }) {
  if (
    response &&
    response.status === HttpStatusCode.UNPROCESSABLE_ENTITY &&
    response.data &&
    response.data.text
  ) {
    toast.error(await response.data.text())
    return
  }
  toast.error(ERROR_REQUEST)
}

export function reportResponseHandler(response: any, fileFormat: FileFormat) {
  switch (fileFormat) {
    case 'pdf':
      downloadFileByType(response, FileFormatEnum.PDF)
      return
    case 'csv':
      downloadFileByType(response, FileFormatEnum.CSV)
      return
    case 'excel':
      downloadFileByType(response, FileFormatEnum.EXCEL)
      return
  }
}
