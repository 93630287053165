import { useEffect } from 'react'
import SaveBar from '@components/SaveBar'
import useCompany, { CompanyHookContext } from '@hooks/useCompany'
import { COMPANY_FORM_ID } from '@constants'
import { FormProvider } from 'react-hook-form'
import CompanyBasicInfoDisplay from './companyBasicInfo/CompanyBasicInfoDisplay'
import CompanyBasicInfoForm from './companyBasicInfo/CompanyBasicInfoForm'
import CreateCompanyFormLoading from './CreateCompanyFormLoading'
import CompanyFormSection from './CompanyFormSection'
import DiscardModal from '@components/DiscardModal'
import { Button, Flexbox, Form, Spacer } from '@components/common'
import CompanyDeprecInfoForm from './companyDeprecInfo/CompanyDeprecInfoForm'
import CompanyDeprecInfoDisplay from './companyDeprecInfo/CompanyDeprecInfoDisplay'
import useCompanyPeriods from '@hooks/useCompanyPeriods'
import usePermission from '@components/Auth/Permissions/usePermission'
import CompanyDetailsLoading from './CompanyDetailsLoading'
import ConfirmDeleteCompanyModal from './ConfirmDeleteCompanyModal'

interface Props {
  companyID?: number
  context: CompanyHookContext
}

const CompanyForm = ({ companyID, context }: Props) => {
  const {
    methods,
    company,
    companyHasHistory,
    cardsState,
    setCardsState,
    isBasicInfoEdited,
    isDeprecInfoEdited,
    fields,
    // isLoading,
    isLoadingCompanyDetails,
    isLoadingCreateCompany,
    isDirty,
    isSaving,
    isValid,
    errors,
    isEmpty,
    shouldBlockNavigation,
    setIsDiscardModalOpen,
    isDiscardModalOpen,
    discardChanges,
    handleSubmit,
    saveCompany,
    deleteCompany,
    isDeleteCompanyModalOpen,
    setIsDeleteCompanyModalOpen,
    isLoadingDeleteCompany,
  } = useCompany({ companyID, context })
  const { canEditCompanies: canEdit } = usePermission()

  const { companyHasPeriods } = useCompanyPeriods({ assetCompanyID: companyID })

  useEffect(() => {
    if (context === 'company-details') {
      discardChanges()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoadingCompanyDetails) return <CompanyDetailsLoading />

  if (isLoadingCreateCompany) return <CreateCompanyFormLoading />

  return (
    <FormProvider {...methods}>
      {/* Save and discard */}
      <SaveBar
        formId={COMPANY_FORM_ID}
        isVisible={context === 'create-company' ? true : isDirty}
        isSubmitting={isSaving}
        isSaveDisabled={!isValid}
        label={
          context === 'create-company' ? 'Add new company' : 'You have unsaved changes'
        }
        saveButtonLabel={context === 'create-company' ? 'Create company' : 'Save changes'}
        shouldBlockNavigation={shouldBlockNavigation}
        openDiscardModal={() => setIsDiscardModalOpen(true)}
      />

      <DiscardModal
        discard={discardChanges}
        modalIsOpen={isDiscardModalOpen}
        closeModal={() => setIsDiscardModalOpen(false)}
      />
      {company && (
        <ConfirmDeleteCompanyModal
          company={company}
          isOpen={isDeleteCompanyModalOpen}
          setIsConfirmDeleteModalOpen={setIsDeleteCompanyModalOpen}
          isLoadingDelete={isLoadingDeleteCompany}
          handleDelete={() => deleteCompany(company)}
        />
      )}

      {/* Main Form UI */}

      <Form id={COMPANY_FORM_ID} noValidate onSubmit={handleSubmit(saveCompany)}>
        <CompanyFormSection
          context={context}
          stateKey="isBasicInfoCardOpen"
          title="Basic information"
          companyHasHistory={companyHasHistory}
          companyHasPeriods={companyHasPeriods}
          cardsState={cardsState}
          setCardsState={setCardsState}
          isEdited={isBasicInfoEdited}
          formElement={
            <CompanyBasicInfoForm fields={fields} context={context} company={company} />
          }
          displayElement={<CompanyBasicInfoDisplay company={company} fields={fields} />}
          canEdit={canEdit}
        />

        <Spacer y="default" />

        {company && (
          <CompanyFormSection
            context={context}
            stateKey="isDeprecInfoCardOpen"
            title="Depreciation information"
            companyHasHistory={companyHasHistory}
            companyHasPeriods={companyHasPeriods}
            cardsState={cardsState}
            setCardsState={setCardsState}
            isEdited={isDeprecInfoEdited}
            formElement={
              <CompanyDeprecInfoForm fields={fields} context={context} company={company} />
            }
            displayElement={<CompanyDeprecInfoDisplay fields={fields} company={company} />}
            canEdit={canEdit}
          />
        )}
        {canEdit && (
          <>
            <Spacer y="default" />

            <Flexbox justifyContent="space-between">
              {context !== 'create-company' && (
                <Button
                  type="button"
                  intent="danger"
                  onClick={() => setIsDeleteCompanyModalOpen(true)}
                >
                  Delete company
                </Button>
              )}

              <Flexbox>
                <Button
                  type="button"
                  onClick={() => setIsDiscardModalOpen(true)}
                  disabled={!isDirty || isSaving}
                >
                  Discard changes
                </Button>

                <Spacer x="mini" />

                <Button
                  type="submit"
                  appearance="primary"
                  disabled={!isDirty || !isEmpty(errors)}
                  loading={isSaving}
                >
                  {context === 'create-company' ? 'Create company' : 'Save changes'}
                </Button>
              </Flexbox>
            </Flexbox>
          </>
        )}
      </Form>
    </FormProvider>
  )
}

export default CompanyForm
