import React, { useState } from 'react'

import { Icon } from '@common'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'

interface Props extends React.HTMLProps<HTMLInputElement> {
  label: string
  grouped?: boolean
}

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  ({ label, grouped = false, tabIndex = 0, ...props }, ref) => {
    const [focus, setFocus] = useState(false)
    const [hover, setHover] = useState(false)

    const [css] = useStyletron()

    let containerStyles = css({ ...styles.container({ grouped }) })

    let checkboxStyles = css({
      ...styles.checkboxBase,
      ...styles.getCheckboxState({ checked: props.checked, hover }),
      ...styles.getFocus({ focus }),
    })

    let checkmarkClassName = css(styles.checkmark)
    let nativeInputClassName = css(styles.nativeInput)
    let labelClassName = css(styles.label)

    return (
      <div
        className={containerStyles}
        onFocus={() => setFocus(!focus)}
        onBlur={() => setFocus(!focus)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <span className={checkboxStyles}>
          <Icon name="Check" className={checkmarkClassName} />
        </span>
        <input
          className={nativeInputClassName}
          type="checkbox"
          id={props.id}
          onChange={props.onChange}
          value={props.value}
          name={props.name}
          disabled={props.disabled}
          checked={props.checked}
          defaultChecked={props.defaultChecked}
          form={props.form}
          required={props.required}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          ref={ref}
          title={props.title}
          style={props.style}
          aria-label={label}
          tabIndex={tabIndex}
        />

        <label htmlFor={props.name} className={labelClassName}>
          {label}
        </label>
      </div>
    )
  }
)
export default Checkbox
