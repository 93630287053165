import React from 'react'
import { LinkProps } from './Link.types'

import { Link } from 'react-router-dom'

import { useStyletron } from 'styletron-react'
import * as styles from './Styles'
import PassClassDown from '@utils/PassClassDown'

interface CustomLinkProps extends LinkProps {
  setPreviousPage?: boolean
}

const LinkComponent: React.FC<CustomLinkProps> = ({
  to,
  external,
  icon,
  setPreviousPage,
  ...props
}) => {
  const [css] = useStyletron()
  let linkStyles = css({
    ...styles.link,
  })
  let iconClassName = css(styles.icon)

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (setPreviousPage && !external) {
      sessionStorage.setItem('previousPage', window.location.pathname)
    }
  }

  return (
    <Link
      to={to}
      className={linkStyles}
      target={external ? '_blank' : ''}
      onClick={handleClick}
    >
      {props.children}
      {icon && <PassClassDown className={iconClassName}>{icon}</PassClassDown>}
    </Link>
  )
}

export default LinkComponent
