import { Company, DEPREC_BASIS_OPTIONS, FieldLabels } from '@api/models'
import {
  Checkbox,
  Divider,
  Fieldset,
  Flexbox,
  Grid,
  Input,
  Note,
  Select,
  Spacer,
  Spinner,
} from '@components/common'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormFieldProps, usePicklist } from '@hooks'
import { CompanyHookContext } from '@hooks/useCompany'

interface Props {
  fields: FieldLabels
  context: CompanyHookContext
  company: Company
}

const CompanyDeprecInfoForm = ({ fields, context, company }: Props) => {
  const {
    register,
    formState: { errors, isSubmitting },
    watch,
    control,
    setValue,
  } = useFormContext<Company>()

  const { getInputProps, getCheckBoxProps, getSelectProps } = useFormFieldProps<Company>({
    isSubmitting,
    errors: errors as any,
    fields,
    register,
  })

  const { picklist, isLoading: isLoadingPicklist } = usePicklist()

  if (isLoadingPicklist)
    return (
      <Flexbox justifyContent="space-between" alignItems="center" height={200}>
        <Spinner />
      </Flexbox>
    )

  return (
    <Grid>
      {context !== 'create-company' && (
        <Grid.Item style={{ display: 'none' }}>
          <Input {...getInputProps('AssetCompanyID')} value={company.AssetCompanyID} />
          <Input {...getInputProps('CM_DESC')} value={company.CM_DESC} />
          <Input {...getInputProps('CM_DESCS')} value={company.CM_DESCS} />
        </Grid.Item>
      )}
      <Grid.Item>
        <Flexbox>
          <Input
            {...getInputProps('CM_YE_MM', 'number')}
            min={1}
            max={12}
            step={1}
            width={'120px'}
            autoFocus={context !== 'create-company'}
          />
          <Spacer x="default" />
          <Input
            {...getInputProps('CM_YE_DD', 'number')}
            min={1}
            max={31}
            step={1}
            width={'120px'}
          />
        </Flexbox>
      </Grid.Item>
      <Grid.Item>
        <Input {...getInputProps('CM_NR_PER', 'number')} min={1} step={1} width={'120px'} />
      </Grid.Item>

      <Grid.Item>
        <Controller
          control={control}
          name="CM_YTD_BCD"
          render={({ field }) => (
            <Select {...getSelectProps('CM_YTD_BCD', field, DEPREC_BASIS_OPTIONS)} />
          )}
        />
      </Grid.Item>

      <Grid.Item>
        <Controller
          control={control}
          name="CM_TAX"
          render={({ field }) => (
            <Checkbox
              {...getCheckBoxProps(field)}
              label="Maintain tax records"
              onChange={(e: any) => {
                field.onChange(e)
                if (!e.target.checked) {
                  setValue('CM_SMH', false)
                  setValue('CM_STAT_CD', null)
                  setValue('CM_B4MH', false)
                  setValue('CM_BOK4_CD', null)
                }
              }}
            />
          )}
        />
      </Grid.Item>

      {watch('CM_TAX') && (
        <>
          <Grid.Item>
            <Divider />
          </Grid.Item>

          <Grid.Item>
            <Note
              content={
                <>
                  To maintain up to two <i>additional</i> tax records, select from the boxes
                  below.
                </>
              }
            />
          </Grid.Item>

          <Grid.Item s={6}>
            <Fieldset legend="State tax records">
              <Flexbox alignItems="center">
                <Controller
                  control={control}
                  name="CM_SMH"
                  render={({ field }) => (
                    <Checkbox
                      {...getCheckBoxProps(field)}
                      label="Maintain"
                      onChange={(e: any) => {
                        field.onChange(e)
                        if (!e.target.checked) {
                          setValue('CM_STAT_CD', null)
                        }
                      }}
                    />
                  )}
                />
                <Spacer x="default" />
                <Controller
                  control={control}
                  name="CM_STAT_CD"
                  render={({ field }) => (
                    <Select
                      {...getSelectProps('CM_STAT_CD', field, picklist.AS_STAT_CD)}
                      menuPlacement="top"
                      label="Default code"
                      autocomplete={true}
                      disabled={!watch('CM_SMH')}
                      width={'132px'}
                      helper="2 character state code"
                      isSearchable
                    />
                  )}
                />
              </Flexbox>
            </Fieldset>
          </Grid.Item>

          <Grid.Item s={6}>
            <Fieldset legend="Other tax records">
              <Flexbox alignItems="center">
                <Controller
                  control={control}
                  name="CM_B4MH"
                  render={({ field }) => (
                    <Checkbox
                      {...getCheckBoxProps(field)}
                      label="Maintain"
                      onChange={(e: any) => {
                        field.onChange(e)
                        if (!e.target.checked) {
                          setValue('CM_BOK4_CD', null)
                        }
                      }}
                    />
                  )}
                />
                <Spacer x="default" />
                <Input
                  {...getInputProps('CM_BOK4_CD')}
                  label="Default Code"
                  width={'132px'}
                  disabled={!watch('CM_B4MH')}
                  helper={'Any 2 character code'}
                  maxLength={2}
                />
              </Flexbox>
            </Fieldset>
          </Grid.Item>
        </>
      )}
    </Grid>
  )
}

export default CompanyDeprecInfoForm
